import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import BlocksRenderer from "../components/blocks-renderer";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import "../styles/page/news.css";
import "../styles/rich-text.css";
import BlockIcon from "../components/homepage/block-icon";
import { Link } from "gatsby";
const ArticlePage = ({ data }) => {
  const article = data.strapiArticle;
  return (
    <Layout as="article">
      <GatsbySeo 
        title={article.seo.metaTitle}
        openGraph={{
          url: `https://www.alphaland.com.vn/tin-tuc/${article.slug}`,
          title: article.seo.metaTitle,
          description: article.seo.metaDescription,
          images: [
            {
              url: article.seo.metaImage.localFile.url,
              alt: article.seo.title,
            }
          ],
        }}
      />
      <div className="about1">
                    <div>
                       <GatsbyImage image={article?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={article.image?.alternativeText} className="project__baner"/>
                        <div className="container">
                         <div className="baner__slider Home__siteTitle">
                             <div>
                             <Link title="Trang-chu" to="/" className="baner__title1">
                                 Trang Chủ /
                             </Link>{" "}
                                  <span className="baner__title1"> {data.strapiListArticlePage.title}</span>
                             </div>
                             <h2 className="news__detail-title">{article.title}</h2>
                        </div>
                      </div>
                    </div>
                </div>
      <div className="container">
      <div className="mg-tp rich-text-component"
          dangerouslySetInnerHTML={{
            __html: article.content?.data?.childMarkdownRemark?.html,
          }}
      />
      
      </div>
      <main className="">
        <BlocksRenderer article={article} blocks={article.blocks  || []} />
      </main>
      <BlockIcon/>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      seo {
        metaDescription
        metaTitle
        metaImage {
          localFile {
            url
            id
          }
          alternativeText
        }
      }
      relatedNews {
        articles {
          title
          description
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          slug
        }
      }
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      blocks {
        ...Blocks
      }
      image {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(quality: 80
              webpOptions: {quality: 80}
              placeholder: BLURRED)
          }
        }
      }
    }
    strapiListArticlePage {
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80
              webpOptions: {quality: 80}
              placeholder: BLURRED)
          }
        }
      }
      id
      title
    }
  }
`;

export default ArticlePage;
