import React from "react";
import { graphql } from "gatsby";
import BlockRichText from "./block-rich-text";
import BlockMedia from "./block-media";
import BlockQuote from "./block-quote";
import BlockSlider from "./block-slider";
import BlockNews from "./block-news";
import "../styles/detail/detail.css"
const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
};

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename];
  if (!Component) {
    return null;
  }
  return <Component data={block} />;
};
const BlocksRenderer = ({ blocks,article}) => {
  return (
    <>
      <div className="news__inner__detail ">
        {blocks.map((block, index) => (
          <Block key={index} prop={`${index}${block.__typename}`} block={block} />
        ))}
      </div>
      <div className="container home__siteTitle news__flex-top">
        <BlockNews newsArticle={article}/>
      </div>
    </>
  );
};

export const query = graphql`
  fragment Blocks on  STRAPI__COMPONENT_SHARED_RICH_TEXT{
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default BlocksRenderer;
