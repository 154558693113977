import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const BlockNews = ({newsArticle}) =>{
    return(
        <div className="project__row-relate">
            <h2>Tin Tức Liên Quan</h2>
            <div className="mt-12">
            { newsArticle.relatedNews?.articles?.length  <= 3 ? (
                  <div className="mt-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 ">
                {newsArticle.relatedNews?.articles?.map((article, key) => (
              
                    <Link
                          key={key}
                          to={`/tin-tuc/${article?.slug}`}
                          className="overflow-hidden hover01 hover01__shadow"
                          title={article?.title}
                        >
                        <figure>
                          <GatsbyImage
                            imgStyle={{transition: ".0.3s ease-in-out 0s"}}
                            className="news__image-higth "
                            image={article.image?.localFile?.childImageSharp?.gatsbyImageData}
                            alt={article?.image?.alternativeText}
                          />
                        </figure>
                          <div className="px-4 py-4 project__shadows">
                          <div className="news__desc">
                              <strong>{article?.publishedAt}</strong>
                          </div>
                          <h3 className="font-bold text-neutral-700">{article?.title}</h3>
                          <p className="mt-2 text-neutral-500 line-clamp-2 line-clamp-mg">
                              {article?.description}
                          </p>
                          </div>
                      </Link>
                  ))}
                </div>
              ):(
                <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
            >
          {newsArticle.relatedNews?.articles?.map((news, key) => (
            <SwiperSlide>
                     <Link
                        key={key}
                        to={`/tin-tuc/${news?.slug}`}
                        className="overflow-hidden hover01 hover01__shadow"
                        title={news?.title}
                      >
                      <figure>
                        <GatsbyImage
                          imgStyle={{transition: ".0.3s ease-in-out 0s"}}
                          className="news__image-higth "
                          image={news?.image?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={news?.image?.alternativeText}
                        />
                      </figure>
                        <div className="px-4 py-4 project__shadow">
                        <div className="news__desc">
                            <strong>{news?.publishedAt}</strong>
                        </div>
                        <h3 className="font-bold text-neutral-700">{news?.title}</h3>
                        <p className="mt-2 text-neutral-500 line-clamp-2 line-clamp-mg">
                            {news?.description}
                        </p>
                        </div>
                    </Link>
                </SwiperSlide>
                ))}
              </Swiper>
              )
              }
            </div>
            {/* {allStrapiArticle.nodes.map((project, id) => (
                     <Link
                        key={id}
                        to={`/tin-tuc/${project.slug}`}
                        className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
                        title={project?.title}
                    >
                      <figure>
                      <GatsbyImage
                          className="news__image-higth "
                          image={project.image?.localFile.childImageSharp.gatsbyImageData}
                          alt={project.image?.alternativeText}
                        />
                      </figure>
                       
                        <div className="px-4 py-4">
                        <div className="news__desc">
                            <strong>{project?.publishedAt}</strong>
                        </div>
                        <h3 className="font-bold text-neutral-700">{project?.title}</h3>
                        <p className="mt-2 text-neutral-500 line-clamp-2">
                            {project?.description}
                        </p>
                        </div>
                    </Link>
                  ))} */}
            </div>
        // </div>
    )
}
export default BlockNews
